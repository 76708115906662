import { doc, updateDoc } from 'firebase/firestore';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TagSelection } from '../../../core/components/tags/TagSelection';
import { InfoText } from '../../../core/components/text/InfoText';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { TextArea } from '../../../core/components/textInput/TextArea';
import { addValueIfExists } from '../../../core/utils/addValueIfExists';
import { getDateStringShort } from '../../../core/utils/dateHandler';
import { editChain, setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { db } from '../../../firebaseConfig';
import { getChainInvitationId } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { safeOnboardingFeedback } from '../utils/safeOnboardingFeedback';
const feedbackGrade = Array.from({ length: 10 }, (_, i) => i + 1).map((i) => ({
  text: i.toString(),
  value: i,
}));
export const OnboardingFeedbackScreen = () => {
  const invitationId = useMainStore(getChainInvitationId);
  const [loading, setLoading] = useState(false);
  const [grade, setGrade] = useState<number>();
  const [feedback, setFeedback] = useState('');
  const { t } = useTranslation();
  const [instagramHandle, setInstagramHandle] = useState('');
  const [website, setWebsite] = useState('');

  const handleNext = async () => {
    setLoading(true);
    try {
      const changeFeedbackObject = {
        ...addValueIfExists('grade', grade),
        ...addValueIfExists('feedback', feedback),
      };

      if (!isEmpty(changeFeedbackObject)) {
        await safeOnboardingFeedback(changeFeedbackObject);
      }
      const changeContactObject = {};
      if (instagramHandle) {
        changeContactObject['socialInfo.instagram'] = instagramHandle;
      }
      if (website) {
        changeContactObject['socialInfo.website'] = website;
      }
      if (!isEmpty(changeContactObject)) {
        await editChain(changeContactObject);
      }
      const updatePromise = updateDoc(doc(db, 'practiceInvitations/' + invitationId), {
        state: 'completed',
        stateChangeDay: getDateStringShort(new Date()),
        ['flags.onboardingCompletedDate']: new Date().toISOString(),
      });
      const flagOnePromise = setChainOnboardingFlag('feedback', 'feedback');
      const flagTwoPromise = setChainOnboardingFlag('onboardingCompleted', 'onboardingCompleted');
      await Promise.all([updatePromise, flagOnePromise, flagTwoPromise]);
    } catch (error) {
      console.error('Error in OnboardingFeedbackScreen', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <OnboardingScreenContainer
      hideProgress
      hideBackButton
      nextButton={{
        text: t('ONBOARDING-FEEDBACK-SCREEN-FINISH-ONBOARDING'),
        onClick: handleNext,
        disabled: !grade,
        loading,
      }}
    >
      <Container>
        <InfoText headline={t('ONBOARDING-FEEDBACK-SCREEN-TITLE')} />
        <StyledGradeH4>{t('ONBOARDING-FEEDBACK-SCREEN-GRADE-TITLE')}</StyledGradeH4>
        <TagSelection
          selected={[grade]}
          tags={feedbackGrade}
          setSelected={(selected) => {
            console.log(selected);
            setGrade(isEmpty(selected) ? undefined : Number(selected[0]));
          }}
          type="singleSelect"
        />
        {grade !== undefined && (
          <>
            <StyledH4>
              {grade <= 7
                ? t('ONBOARDING-FEEDBACK-SCREEN-ADDITIONAL-FEEDBACK-TITLE')
                : t('ONBOARDING-FEEDBACK-SCREEN-ADDITIONAL-FEEDBACK-TITLE-POSITIVE')}
            </StyledH4>
            <TextArea
              initialValue={feedback}
              onChange={setFeedback}
              rows={5}
              placeholder={t('ONBOARDING-FEEDBACK-SCREEN-ADDITIONAL-FEEDBACK-PLACEHOLDER')}
            />
            <StyledH4>{t('ONBOARDING-FEEDBACK-SCREEN-GET-USER-CONTACT')}</StyledH4>
            <SingleTextInput
              value={instagramHandle}
              onChange={setInstagramHandle}
              placeholder={t('ONBOARDING-FEEDBACK-SCREEN-INSTAGRAM-PLACEHOLDER')}
            />
            <SingleTextInput
              value={website}
              onChange={setWebsite}
              placeholder={t('ONBOARDING-FEEDBACK-SCREEN-WEBSITE-PLACEHOLDER')}
            />
          </>
        )}
      </Container>
    </OnboardingScreenContainer>
  );
};

const StyledH4 = styled.h4`
  color: ${colors.Medium};
  text-align: left;
  align-self: flex-start;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const StyledGradeH4 = styled(StyledH4)`
  margin-top: 20px;
`;

const Container = styled.div`
  width: 100%;
`;
