import { isEmpty } from 'lodash';
import { forwardRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { getAppBrandingColors, getAppBrandingImages } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { H4 } from '../../../../styles/textStyles';

export const MockPracticeImagesCard = forwardRef<HTMLDivElement>((_, ref) => {
  const imageUrls = useMainStore(getAppBrandingImages);
  const appColors = useMainStore(getAppBrandingColors);

  const loading = isEmpty(imageUrls);

  return (
    <Card ref={ref}>
      <Headline $color={appColors.Medium}>{'Unsere Praxis'}</Headline>
      {loading || !imageUrls ? (
        <SkeletonWrapper>
          <Skeleton height={160} borderRadius={8} />
          <ImagesVertical>
            <Skeleton height={60} width={75} borderRadius={8} />
            <Skeleton height={60} width={75} borderRadius={8} />
            <Skeleton height={60} width={75} borderRadius={8} />
            <Skeleton height={60} width={75} borderRadius={8} />
          </ImagesVertical>
        </SkeletonWrapper>
      ) : (
        <ImagesContainer>
          <MainImage src={imageUrls[0]} alt="main_image" />
          <ImagesVertical>
            {imageUrls.slice(1).map((imageUrl, index) => (
              <SmallImageContainer>
                <SmallImage key={'image' + index} src={imageUrl} alt={'image_' + index} />
              </SmallImageContainer>
            ))}
          </ImagesVertical>
        </ImagesContainer>
      )}
    </Card>
  );
});

const Headline = styled(H4)<{ $color: string }>`
  font-weight: 500;
  color: ${({ $color }) => $color};
  text-align: left;
`;
const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

const SmallImageContainer = styled.div`
  min-width: 75px;
  max-width: 75px;
  height: 60px;
  border-radius: 8px;
`;

const SmallImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const MainImage = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 16px;
`;

const ImagesVertical = styled.div`
  display: flex;
  gap: 5px;
  overflow-x: hidden;
  width: 100%;
`;

const Card = styled.div`
  margin: 0 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 20px 10px;
  display: flex;
  gap: 20px;
  overflow: hidden;
  flex-direction: column;
  background-color: ${colors.White};
`;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
