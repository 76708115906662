import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressButton } from '../../core/components/buttons/ProgressButton';
import { ConfirmationWaiting } from '../../core/components/loading/ConfirmationWaiting';
import { InfoText } from '../../core/components/text/InfoText';
import { getChainPracticeName } from '../../store/chainSlice';
import { useMainStore } from '../../store/mainStore';
import { MainScreenContainer } from '../overview/components/MainScreenComponent';
import { useCreateBillingPortalSession } from './utils/useCreateBillingPortalSession';

export const PracticeSettingsInvoiceScreen = () => {
  const practiceId = useParams().practiceId;
  const practiceName = useMainStore((state) => getChainPracticeName(state, practiceId));

  const { loadingStripeSessionUrl, stripeSessionUrl } = useCreateBillingPortalSession();

  return (
    <MainScreenContainer>
      <Container>
        <InfoText headline={'Rechnungsinformationen'} />
        <ConfirmationWaiting
          loading={loadingStripeSessionUrl}
          text={
            loadingStripeSessionUrl
              ? 'Dein Rechnungslink für den Standort ' + practiceName + ' wird generiert.'
              : 'Rechnungslink generiert'
          }
        />
        <ProgressButton
          disabled={loadingStripeSessionUrl}
          text="Rechnungsportal öffnen"
          onClick={() => window.open(stripeSessionUrl, '_self')}
        />
      </Container>
    </MainScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 200px;
`;
