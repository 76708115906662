import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../core/components/buttons/CustomButton';
import { LoadingSpinner } from '../../../core/components/loading/LoadingSpinner';
import { useWindowDimensions } from '../../../core/utils/useWindowDimensions';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { Body } from '../../../styles/textStyles';
import { useGetCustomSubscriptionItems } from '../utils/useGetCustomSubscriptionItems';
import { CheckoutBillLine } from './CheckoutBillLine';
import { formatMoney } from './CheckoutCalculatorModal';

export const CheckoutBillCustom = () => {
  const { items, loadingItems, bruttoPrice, nettoPrice, tax } = useGetCustomSubscriptionItems();
  const { isMobileWidth } = useWindowDimensions();
  const [showDetails, setShowDetails] = useState(!isMobileWidth);
  useEffect(() => {
    setShowDetails(!isMobileWidth);
  }, [isMobileWidth]);

  const Items = items?.map((item, index) => (
    <CheckoutBillLine
      active
      key={index}
      title={item.quantity > 1 ? `${item.quantity}x ${item.name}` : item.name}
      value={formatMoney(item.price)}
      text={item.description}
    />
  ));

  return (
    <>
      {loadingItems ? (
        <LoadingContainer>
          <LoadingSpinner color={colors.Primary} size={40} />
        </LoadingContainer>
      ) : (
        <>
          <DetailsButton onClick={() => setShowDetails(!showDetails)}>
            <Body>{'Details anzeigen'}</Body>
            <RotatingIconButton name="ArrowDown" $isRotated={showDetails} size={32} />
          </DetailsButton>
          <Details $display={showDetails}>{Items}</Details>

          <Divider />
          <div>
            <CheckoutBillLine title="Nettobetrag" value={formatMoney(nettoPrice)} active />
            <CheckoutBillLine title="Mehrwertsteuer" value={formatMoney(tax)} />
            <CheckoutBillLine title="Bruttobetrag" value={formatMoney(bruttoPrice)} />
          </div>
        </>
      )}
    </>
  );
};

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.Light};
`;

const DetailsButton = styled(CustomButton)`
  width: 100%;
  display: none;

  justify-content: space-between;
  align-items: center;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: flex;
  }
`;

const RotatingIconButton = styled(GenericSvg)<{ $isRotated: boolean }>`
  transform: rotate(${(props) => (props.$isRotated ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
`;

const Details = styled.div<{ $display: boolean }>`
  display: ${(props) => (props.$display ? 'flex' : 'none')};
  gap: 20px;
  flex-direction: column;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;
