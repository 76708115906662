import styled from 'styled-components';
import { CustomButton } from '../../../../core/components/buttons/CustomButton';
import { getAppBrandingColors, getAppBrandingMapsInfo } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { generateMapsImage } from '../../utils/generateMapsImage';

export const MockMapsImage = () => {
  const googleMapsInfo = useMainStore(getAppBrandingMapsInfo);
  const appBrandingColors = useMainStore(getAppBrandingColors);
  const imageUrl =
    googleMapsInfo?.mapsImageUrl ??
    generateMapsImage(48.138743627495195, 11.57298041094041, appBrandingColors.Primary, true);
  const onClick = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${googleMapsInfo?.latitude},${googleMapsInfo?.longitude}&query_place_id=${googleMapsInfo?.googleMapsId}`,
    );
  };
  return (
    <Container onClick={onClick}>
      <StyledImage src={imageUrl} alt="Location Map" />
    </Container>
  );
};

const Container = styled(CustomButton)`
  height: 180px;
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
`;

const StyledImage = styled.img`
  height: 200px;
  width: 100%;
  object-fit: cover;
`;
