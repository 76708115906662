import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { colors } from '../../../styles/colors';
import { ButtonText, H4, Small } from '../../../styles/textStyles';
import { Therapist } from '../../../types/Practices/TherapistType';
import { useHandleTherapistsLinks } from '../utils/useHandlePracticeTherapistsLinks';

type TherapistLinksProps = {
  practiceId: string;
  therapist: Therapist;
};

export const TherapistLinks = (props: TherapistLinksProps) => {
  const { copyLink, openLink, therapistProfilePicture, therapistUrl } = useHandleTherapistsLinks(
    props.practiceId,
    props.therapist,
  );

  return (
    <Container>
      <LeftContainer>
        {therapistProfilePicture ? (
          <Avatar src={therapistProfilePicture?.assetUrl} />
        ) : (
          <GenericSvg name="Avatar" size={48} />
        )}
        <LinkContainer>
          <H4>
            {props.therapist.firstName} {props.therapist.lastName}
          </H4>
          <StyledSmall>{therapistUrl}</StyledSmall>
        </LinkContainer>
      </LeftContainer>
      <LinkButtonsContainer>
        <StyledButtonText onClick={copyLink}>Link kopieren</StyledButtonText>
        <StyledButtonText onClick={openLink}>Link öffnen</StyledButtonText>
      </LinkButtonsContainer>
    </Container>
  );
};

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;

const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButtonText = styled(ButtonText)`
  padding: 0 20px;
  text-decoration: underline;
  cursor: pointer;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  max-width: 720px;
  align-items: center;
  justify-content: space-between;
`;
