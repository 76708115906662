import { doc, getDoc, setDoc } from 'firebase/firestore';
import { isValidIBAN } from 'ibantools-germany';
import { checkVAT, countries } from 'jsvat';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunction, LoaderFunctionArgs, Navigate, useLoaderData, useNavigate } from 'react-router-dom';
import { InfoText } from '../../../core/components/text/InfoText';
import { addValueIfExists } from '../../../core/utils/addValueIfExists';
import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { db } from '../../../firebaseConfig';
import { PracticePayout } from '../../../types/Practices/PracticePayoutType';
import { BankInformationForm } from '../components/BankInformationForm';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { PayoutAddressInformation } from '../components/PayoutAddressInformation';
import { useHandleOnSubmitKeyPress } from '../utils/useHandleOnSubmitKeyPress';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

export const practicePayoutDataLoader: LoaderFunction = async ({ params }: LoaderFunctionArgs) => {
  const practiceId = params.practiceId;
  if (!practiceId) return null;
  try {
    const practiceSnapshot = await getDoc(doc(db, 'practices/' + practiceId + '/documents/payoutData'));
    const practiceData = practiceSnapshot?.data() as undefined | PracticePayout;
    return practiceData ?? null;
  } catch (error) {
    console.error(error);
  }
};

export const OnboardingPracticePayoutDataScreen = () => {
  const practiceData = useLoaderData() as null | PracticePayout;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { practiceId, practiceIndex, practiceName } = useOnboardingPracticeInfo();

  const [loading, setLoading] = useState(false);
  const [currentBankStep, setCurrentBankStep] = useState(practiceData ? 2 : 0);
  const [currentAddressStep, setCurrentAddressStep] = useState(practiceData ? 5 : 0);
  const currentStep = currentBankStep + currentAddressStep;

  const payoutAccount = practiceData?.payoutAccount;
  const payoutAddress = practiceData?.payoutAddress;

  const [bankFields, setBankFields] = useState({
    owner: payoutAccount?.accountHolder ?? '',
    iBan: payoutAccount?.iban ?? '',
    bankName: payoutAccount?.bankName ?? '',
    bic: payoutAccount?.bic ?? '',
    copyId: practiceData?.onboardingCopyIBanId ?? '',
  });
  const [addressFields, setAddressFields] = useState({
    practiceName: practiceData?.practiceName ?? '',
    street: payoutAddress?.street ?? '',
    cityCode: payoutAddress?.cityCode ?? '',
    city: payoutAddress?.city ?? '',
    taxId: practiceData?.taxId ?? '',
    houseNumber: payoutAddress?.houseNumber ?? '',
    copyId: practiceData?.onboardingCopyAddressId ?? '',
  });

  const handleNext = async () => {
    if (!practiceId || currentStep < 7) return;
    try {
      const data = {
        payoutAddress: {
          payoutRecipient: addressFields.practiceName,
          street: addressFields.street,
          houseNumber: addressFields.houseNumber,
          cityCode: addressFields.cityCode,
          city: addressFields.city,
        },
        payoutAccount: {
          accountHolder: bankFields.owner,
          iban: bankFields.iBan,
          ...addValueIfExists('bankName', bankFields.bankName),
          ...addValueIfExists('bic', bankFields.bic),
        },
        ...addValueIfExists('taxId', addressFields.taxId),
        practiceName: addressFields.practiceName,
        practiceId: practiceId,
        onboardingCopyIBanId: bankFields.copyId,
        onboardingCopyAddressId: addressFields.copyId,
      };
      await setDoc(doc(db, 'practices/' + practiceId + '/documents/payoutData'), data);
      await setChainOnboardingFlag(`practices.${practiceId}.payoutData`, 'practices-payoutData');
      navigate('../practice-therapists');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const validIBan = isValidIBAN(bankFields.iBan);
  const invalidTaxId = Boolean(addressFields.taxId && checkVAT(addressFields.taxId, countries).isValid === false);

  useHandleOnSubmitKeyPress(handleNext);

  const handleBack = () => {
    navigate('../practice-data-info');
  };

  if (!practiceId || practiceIndex === undefined) {
    return <Navigate to="../practices-names" />;
  }
  return (
    <OnboardingScreenContainer
      handleBack={handleBack}
      nextButton={{
        currentStep: currentStep,
        numberOfSteps: 7,
        onClick: handleNext,
        disabled: !validIBan || invalidTaxId,
        loading: loading,
      }}
    >
      <InfoText
        preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
        headline={t('ONBOARDING-PRACTICE-PAYOUT-DATA-SCREEN-HEADLINE')}
        text={[t('ONBOARDING-PRACTICE-PAYOUT-DATA-SCREEN-DESCRIPTION')]}
      />
      <BankInformationForm
        setCurrentStep={setCurrentBankStep}
        practiceIndex={practiceIndex}
        fields={bankFields}
        setFields={setBankFields}
        focusOnFirstInput
      />
      <PayoutAddressInformation
        setCurrentStep={setCurrentAddressStep}
        practiceIndex={practiceIndex}
        fields={addressFields}
        setFields={setAddressFields}
      />
    </OnboardingScreenContainer>
  );
};
