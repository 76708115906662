import styled from 'styled-components';
import { H4 } from '../../../styles/textStyles';
import { DrawerNavItem } from './DrawerNavItem';

type Props = {
  headline: string;
  items: { title: string; path: string; children?: { title: string; path: string }[] }[];
  toggleDrawer: () => void;
};

export const DrawerCategory = ({ headline, items, toggleDrawer }: Props) => {
  const path = window.location.pathname;
  return (
    <Category>
      <StyledH4>{headline}</StyledH4>
      <Items>
        {items.map((item) => {
          return (
            <>
              <DrawerNavItem key={item.path} {...item} closeDrawer={toggleDrawer} isParent />
              {path.includes(item.path) &&
                item.children?.map((child) => (
                  <Items>
                    <DrawerNavItem
                      key={item.path}
                      path={child.path}
                      closeDrawer={toggleDrawer}
                      title={child.title}
                    ></DrawerNavItem>
                  </Items>
                ))}
            </>
          );
        })}
      </Items>
    </Category>
  );
};

const Category = styled.div``;

const StyledH4 = styled(H4)``;

const Items = styled.div`
  padding-left: 20px;
`;
