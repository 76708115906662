import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from '../../../core/components/loading/LoadingSpinner';
import { getAppBrandingImages } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';
import { AppBrandingMediaUpload } from './AppBrandingMediaUpload';
import { AppBrandingPracticeImagePreview } from './AppBrandingPracticeImagePreview';

export const AppBrandingPracticeImages = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { practiceId } = useAppBrandingPracticeInfo();
  const practiceImages = useMainStore(getAppBrandingImages);
  const addPracticeImage = useMainStore((state) => state.addAppBrandingImage);
  const setPracticeImages = useMainStore((state) => state.setAppBrandingImages);

  const [loadingNames, setLoadingNames] = useState<String[]>([]);

  const MAX_FILE_SIZE_MB = 10;
  const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleFile = async (files: FileList) => {
    const filesArray = Array.from(files);
    filesArray.forEach(async (file) => {
      const fileSizeMB = Math.round((file.size / 1024 / 1024) * 1000) / 1000;
      if (file.size > MAX_FILE_SIZE) {
        alert(
          `Die Datei ist zu groß (${fileSizeMB}MB). Bitte wähle eine Datei mit einer Größe von maximal ${MAX_FILE_SIZE_MB}MB.`,
        );
        return;
      }
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        setLoadingNames((prev) => [...prev, file.name]);

        const storage = getStorage();
        const storageRef = ref(storage, `practiceImages/${practiceId}/${file.name}`);
        try {
          const snapshot = await uploadBytes(storageRef, file);
          const downloadURL = await getDownloadURL(snapshot.ref);
          addPracticeImage(downloadURL);
        } catch (error) {
          console.error('Error uploading file:', error);
          setEventIndicator('error', `Es ist ein Fehler bei ${file.name} aufgetreten. Bitte versuche es erneut.`);
        } finally {
          setLoadingNames((prev) => prev.filter((name) => name !== file.name));
        }
      } else {
        alert('Bitte verwende nur Dateien vom Typ JPG oder PNG.');
      }
    });
  };

  const deleteImage = (index: number) => {
    const imageToDelete = practiceImages?.[index];
    const newImages = practiceImages?.filter((_, i) => i !== index);

    const storage = getStorage();
    const storageRef = ref(storage, imageToDelete);
    deleteObject(storageRef);
    setPracticeImages(newImages);
    setEventIndicator('success', 'Das Bild wurde erfolgreich gelöscht.');
  };

  const moveLeft = (index: number) => {
    if (!practiceImages) return;
    if (index > 0) {
      const newImages = [...practiceImages];
      [newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]];
      setPracticeImages(newImages);
    }
  };

  const moveRight = (index: number) => {
    if (!practiceImages) return;
    if (index < practiceImages.length - 1) {
      const newImages = [...practiceImages];
      [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
      setPracticeImages(newImages);
    }
  };

  return (
    <Container>
      <AppBrandingMediaUpload
        maxMBFileSize={MAX_FILE_SIZE_MB}
        handleUpload={handleFile}
        canUploadMultipleFiles
        type="image"
      />
      <ImagesPreview>
        {practiceImages?.map((image, index) => (
          <AppBrandingPracticeImagePreview
            key={index}
            imageUrl={image}
            moveRight={index !== practiceImages.length - 1 ? () => moveRight(index) : undefined}
            moveLeft={index !== 0 ? () => moveLeft(index) : undefined}
            handleDelete={() => deleteImage(index)}
          />
        ))}
        {loadingNames.map((name, index) => (
          <LoadingContainer key={'uploading' + index + name}>
            <LoadingSpinner color={colors.Primary} />
          </LoadingContainer>
        ))}
      </ImagesPreview>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const ImagesPreview = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const LoadingContainer = styled.div`
  width: 142px;
  height: 100px;
  background-color: ${colors.Light};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
