import styled from 'styled-components';
import { GenericSvg } from '../../../../assets/svgs/GenericSVG';
import { getAppBrandingColors, getAppBrandingLogoUrl } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { H3 } from '../../../../styles/textStyles';

export const MockLogo = () => {
  const appColors = useMainStore(getAppBrandingColors);
  const logoUrl = useMainStore(getAppBrandingLogoUrl);

  return (
    <LogoPlaceholder>
      {logoUrl ? (
        <Logo src={logoUrl} alt="Logo" />
      ) : (
        <>
          <GenericSvg name="Checkmark" size={20} primaryColor={appColors.Primary} />
          <LogoText $color={appColors.Primary}>{'DEIN LOGO'}</LogoText>
        </>
      )}
    </LogoPlaceholder>
  );
};

const LogoText = styled(H3)<{ $color: string }>`
  font-weight: 500;
  color: ${({ $color }) => $color};
`;

const LogoPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding-top: 10px;
  height: 50px;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
