import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { Body, ButtonText, H2, H3, H4, Small } from '../../styles/textStyles';
import { useOnboardingPracticeInfo } from '../onboarding/utils/useOnboardingPracticeInfo';
import { MainScreenContainer } from '../overview/components/MainScreenComponent';
import { TherapistLinks } from './components/TherapistLinks';
import { useHandlePracticeLinks } from './utils/useHandlePracticeLinks';

export const PracticeSettingsLinksScreen = () => {
  const { practiceName, practiceId } = useOnboardingPracticeInfo();
  const { practiceQrCode, practiceLink, copyLink, openLink, therapists, loadingPractice, loadingTherapists } =
    useHandlePracticeLinks();

  return (
    <MainScreenContainer>
      <Container>
        <StyledH2>Wichtige Links für den Standort {practiceName}</StyledH2>
        <HeadlineContainer>
          <StyledH3>Praxislink Standort {practiceName}</StyledH3>
          <StyledBody>
            Der Praxislink dient als allgemeiner Zugang zu Physiofit. Mit diesem Link hast du Zugriff auf alle Patienten
            und kannst auswählen über welchen Therapeuten eingeladen werden soll. So können beispielsweise auch durch
            den Empfang Patienten-Einladungen versendet werden.
          </StyledBody>
        </HeadlineContainer>
        <PracticeLinkContainer>
          {loadingPractice ? (
            <>
              <Skeleton width={120} height={120} />
              <div style={{ width: '100%' }}>
                <Skeleton height={20} count={2} />
              </div>
            </>
          ) : (
            <>
              <QRCodeContainer>
                <QRCode src={practiceQrCode} alt="QR Code" />
                <LinkContainer>
                  <H4>Praxislink Standort {practiceName}</H4>
                  <StyledSmall>{practiceLink}</StyledSmall>
                </LinkContainer>
              </QRCodeContainer>
              <LinkButtonsContainer>
                <StyledButtonText onClick={copyLink}>Link kopieren</StyledButtonText>
                <StyledButtonText onClick={openLink}>Link öffnen</StyledButtonText>
              </LinkButtonsContainer>
            </>
          )}
        </PracticeLinkContainer>
        <HeadlineContainer>
          <StyledH3>Therapeuten Links</StyledH3>
          <StyledBody>
            {
              'Hier findest du die individuellen Links für deine Therapeuten. Damit können sie direkt loslegen und Patienten zu Physiofit einladen.'
            }
          </StyledBody>
        </HeadlineContainer>
        <TherapistsContainer>
          {loadingTherapists ? (
            <Skeleton height={50} count={3} />
          ) : (
            therapists?.map((therapist) => (
              <TherapistLinks key={therapist.id} practiceId={practiceId ?? ''} therapist={therapist} />
            ))
          )}
        </TherapistsContainer>
      </Container>
    </MainScreenContainer>
  );
};

const TherapistsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 365px;
`;

const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButtonText = styled(ButtonText)`
  padding: 0 20px;
  text-decoration: underline;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 720px;
  padding-bottom: 200px;
`;

const StyledH2 = styled(H2)`
  color: ${colors.Dark};
`;

const HeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
  padding-bottom: 15px;
`;

const PracticeLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

const QRCode = styled.img`
  width: 120px;
  height: 120px;
`;
